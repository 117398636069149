#topUtility{
    padding: ($content-padding * 2) $content-padding;
    background-color: $primary;
    color: black;

    a{
        color: white !important;
    }

    .card-accueil{
        margin: 5rem 0;
        .card{
            box-shadow: $box-shadow;
        }
    }
}