#topFeature{
    padding: ($content-padding * 2) $content-padding;
    background-color:rgb(102, 51, 102);
    color: white;

    .feature-accueil{
        h3{
            min-height: 2rem;
        }
    }

}