#feature{
    padding: ($content-padding * 2) $content-padding;
    background-color: white;
    color: black;

    .feature-accueil{
        h3{
            min-height: 2rem;
        }
    }

    .feature-resa{
        padding: 3rem 0;
        .col{
            background-color: $secondary;
            color: $white;
            box-shadow: $box-shadow;
            padding: 3rem 0;
            i{
                display: block;
                margin: 1rem 0;
            }

            h3{
                font-size: 3rem;
            }

            p, a{
                font-size: 1.5rem;
            }
        }
    }

    .content-info{
        margin: 5rem;
        border-bottom: 1px solid $white;
        .col{
            text-align: center;
            i{
                display: block;
                margin: 1rem 0 ;
                color: $white;
                font-size: 3rem;
            }
            p{
                font-size: 1.3rem;
            }
        }
    }

    .profile-situation{
        color: black;
        h2{
            color: white;
        }
        .imgs{
            img{
                border: 1px solid $white;
                box-shadow: $box-shadow;
            }
        }
        .card-body{
            .card-title{
                padding: 0.5rem;
                background: $secondary;
            }
            p{
                margin-bottom: 0.5rem;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                
            }
        }
        
    }

    .galerie{
        padding: 3rem 0 5rem 0;
        .col{
            a{
                box-shadow: $box-shadow;
                display: block;
                border: 1px solid $white;
                cursor: pointer;
            }
        }

        .col.hover-zoom-left, .col.hover-zoom-right{
            overflow: hidden;
            img{
                transform: scale(1) rotate(0);
                filter: grayscale(0);
                transition: all 1s;
            }
        }

        .col.hover-zoom-left:hover{
            cursor: pointer;
            img{
                transform: scale(1.2) rotate(-2deg);
                filter: grayscale(90%);
                transition: all 1s;
            }
        }

        .col.hover-zoom-right:hover{
            cursor: pointer;
            img{
                transform: scale(1.2) rotate(2deg);
                filter: sepia(60%);
                transition: all 1s;
            }
        }
    }

}

.restaurant #feature,
.annexes #feature{
    padding: ($content-padding * 2) $content-padding;
    background-color: $secondary;
    color: white;
}