header{
    padding: 0;
    color: white;
    z-index: 1020;
    a{
        color: white !important;
    }

    #navbar-top-mobile{
        background-color: #c5beb9;
    }

    .navbar{
        z-index: 1030;
        background-color: $primary;
        border-bottom: 2px solid white;
        box-shadow: $box-shadow;
        width: 100%;
        @include media-breakpoint-up(lg) { position: fixed; }
        .bi{
            color: white;
        }
        .navbar-brand{
            width: 20% !important;
            transition: 0.8s !important;
        }
        .nav-item{
            font-family: 'Suez One', serif;
            @include media-breakpoint-only(xs) { a { font-size: 1.3rem; } }
            @include media-breakpoint-only(sm) { a { font-size: 1.3rem; } }
            @include media-breakpoint-only(md) { a { font-size: 1.1rem; } }
            @include media-breakpoint-only(lg) { a { font-size: 1rem; } }
            @include media-breakpoint-only(xl) { a { font-size: 1.3rem; } }
            @include media-breakpoint-only(xxl) { a { font-size: 1.5rem; } }

            .nav-link{
                padding-bottom: 0;
                margin-bottom: 0.5rem;
            }

            .nav-link:hover{
            border-bottom: 1px solid white; 
            }
        }

        .nav-item.active{
            a{
               border-bottom: 1px solid white; 
            }
        }
    }

    .move-up{
        margin-top: -50px;
        transition: .7s;
    }

    .move-down{
        margin-top: 0;
        transition: .7s;
    }

    .fixed-top{
        .navbar-brand{
            width: 10% !important;
            transition: 0.8s !important;
        }
    }

}