#subMain{
    padding: ($content-padding * 2) $content-padding;
    background-color: $gray-800;
    color: rgb(0, 0, 0);

    .card-horizontal-accueil{
        .row{
            .col{
                text-align: center;
                box-shadow: $box-shadow;
                i{
                    display: block;
                    margin: 1rem 0;
                    color: $secondary;
                }
            }

            .col.hover-zoom{
                overflow: hidden;
                img{
                    transform: scale(1) rotate(0);
                    filter: grayscale(0);
                    transition: all 1s;
                }
            }

            .col.hover-zoom:hover{
                cursor: pointer;
                img{
                    transform: scale(1.2) rotate(-2deg);
                    filter: grayscale(90%);
                    transition: all 1s;
                }
            }

            .col.hover-zoom.rotate-left:hover{
                cursor: pointer;
                img{
                    transform: scale(1.2) rotate(2deg);
                    filter: grayscale(90%);
                    transition: all 1s;
                }
            }
        }
    }

    .feature-chambres{
        .row{
            .col{
                border: 1px solid $white;
                color: white;
                i{
                    display: block;
                    margin: 1rem 0 2rem 0;
                    color: $white;
                }
                h3{
                    font-size: 2rem;
                    text-align: center;
                    margin-bottom: 1rem;
                }
                p{
                    font-size: 1.1rem;
                }
            }

            .col:nth-last-child(-n+1){
                p{
                    font-size: 1.3rem;
                    text-align: center;
                }
            }
        }
    }

    .card-horizontal-accueil > div:nth-child(-n+2){
        padding-bottom: 4rem;
    }

    .card-accueil{
        .row{
            .col{
                text-align: center;
                .card{
                    box-shadow: $box-shadow;
                    i{
                        display: block;
                        margin: 1rem 0;
                        color: $primary;
                    }
                    h5{
                        font-size: 2rem;
                        text-align: center;
                        margin-bottom: 1rem;
                    }
                    p{
                        font-size: 1.1rem;
                    }
                }
            }
        }
    }

    #galeriematériaux1-block,
    #galeriesurmesure1-block,
    #galeriefunéraire1-block{
        h3{
            color: white;
        }
    }

    .galerie{
        padding: 0.25rem 0 5rem 0;
        .col{
            padding: 0.75rem;
            a{
                box-shadow: $box-shadow;
                display: block;
                border: 1px solid $white;
                cursor: pointer;
            }
        }

        .col.hover-zoom-left, .col.hover-zoom-right{
            overflow: hidden;
            img{
                transform: scale(1) rotate(0);
                filter: grayscale(0);
                transition: all 1s;
            }
        }

        .col.hover-zoom-left:hover{
            cursor: pointer;
            img{
                transform: scale(1.2) rotate(-2deg);
                filter: grayscale(90%);
                transition: all 1s;
            }
        }

        .col.hover-zoom-right:hover{
            cursor: pointer;
            img{
                transform: scale(1.2) rotate(2deg);
                filter: sepia(60%);
                transition: all 1s;
            }
        }
    }
}