#main{
    padding: ($content-padding * 2) $content-padding;
    background-color: $white;
    color: rgb(0, 0, 0);

    a{
        color: white !important;
    }

    .feature-sur-mesure{
        .row{
            margin-top: 5rem;
            margin-bottom: 5rem;
            .col{        
                box-shadow: $box-shadow;
                border: 1px solid white;
                padding: 5rem 2rem !important;
                background-color: $primary;
                color: white;
                i{
                    display: block;
                    margin: 1rem auto;
                    width: 60px;
                    text-align: center;
                }
                h3{
                    font-size: 2.5rem;
                    text-align: center;
                }
                p{
                    font-size: 1.2rem;
                    text-align: center;
                }
            }
        }
    }

    .profile{
        margin-top: 5rem;;
        color: black;
        h2{
            color: white;
        }
        .imgs{
            img{
                border: 1px solid $white;
                box-shadow: $box-shadow;
            }
        }
        .card{
            .card-body{
                background-color: $primary;
                box-shadow: $box-shadow;
                .card-title{
                    padding: 0.5rem;
                    background: $secondary;
                }
                p{
                    margin-bottom: 0.5rem;
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                    color: white;
                    
                }
            }
        }
    }
}

.off-line{
    #main{
        margin: 0;
        padding: 0;
        .overflow-hidden{
            height: 650px;
            img{
                margin-top: -250px;;
            }
        }
        .container{
            h1{
                margin-top: 2rem;
            }
            p{
                font-size: 1.5rem;
                .tel{
                    background-color: $secondary;
                    color: white;
                    width: 300px;
                }
            }
        }
    }
}