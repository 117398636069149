@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css2?family=Suez+One&display=swap');

body{
    font-family: "Roboto";
}

.bi-icon {
    font-size: 2rem; 
    color: cornflowerblue;
}

h1{
    border-bottom: 1px solid $black;
}

/* Content Text */
.row-content-text{
    .col-text{
        h2, p {
            font-size: 1.2rem;
            margin-bottom: 0.5rem;
        }
    }
    .col-img{
        img{
            box-shadow: $box-shadow;
        }
    }
}

.modal-open{
    #myModalPopup{
        .modal-content{
            background-image: url('/assets/images/bg-popup.jpg');
            background-position: 0vw;
            background-repeat: no-repeat;
            min-height: 450px;
            .col-content{
                padding: 1rem;
                background-color: rgba($color: #ffffff, $alpha: 0.35);
            }
        }
        .modal-content.no-size{
            background-position: -10vw;
        }
    }
}
