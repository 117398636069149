#subFeature{
    padding: ($content-padding * 2) $content-padding;
    background-color: $gray-800;
    color: white;

    .content-info{
        box-shadow: $box-shadow;
        border: 1px solid white;
        margin: 5rem 3rem;
        padding: 3rem 2rem;
        background-color: $secondary;
        .col-title{
            h3{
                i{
                    display: block;
                    margin-bottom: 1rem;
                    font-size: 4rem;
                }
                font-size: 2rem;
            }
        }
        .col-text{
            p{
                font-size: 4rem;
            }
        }
    }

    .card-pelet{
        .card{
            box-shadow: $box-shadow;
            border: 1px solid white;
            margin: 1rem 3rem;
            background-color: $primary;
            .card-title{
                i{
                    display: block;
                    margin-bottom: 1rem;
                    font-size: 4rem;
                }
                font-size: 3rem;
                text-align: center;
            }
            .card-text{
                p{
                    font-size: 1.5rem;
                    text-align: center;
                }
            }
        
            .col.hover-zoom{
                overflow: hidden;
                img{
                    transform: scale(1) rotate(0);
                    filter: grayscale(0);
                    transition: all 1s;
                }
            }
    
            .col.hover-zoom:hover{
                cursor: pointer;
                img{
                    transform: scale(1.2) rotate(-2deg);
                    filter: grayscale(90%);
                    transition: all 1s;
                }
            }
        }

        .row:first-child .card{
            margin-top: 5rem;
        }

        .row:last-child .card{
            margin-bottom: 5rem;
        }
        
    }

}